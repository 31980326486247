<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <div class="d-flex align-items-center">
      <h1 class="d-inline-block mb-0">FAQ's Management</h1>
      <base-button class="ml-auto mr-1 white-outline-button title-btn-padding button-font status-indicator" :class="indicatorClass" @click="modals.addData.visibility = true">
        <span class="pl-2">Add From URL </span>
      </base-button>
      <router-link class="ml-2"
                   :to="{ name: 'NewFaq',
                          params: { newFlag: true }}">
        <base-button class="btn-secondary-action title-btn-padding button-font">
          Add New
        </base-button>
      </router-link>
    </div>
    <div class="mt-4 cardStyle" v-loading="loaders.checkboxButton || loaders.faq">
      <!--   Buttons Div   -->
      <div id="select-option-container" :class="{'invisible': (!selectedRows.length)}" class="mt-4 ml-4">
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :disabled="loaders.checkboxButton"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :disabled="loaders.checkboxButton"
        >Deactivate
        </base-button>
      </div>
      <el-table
        ref="multipleTable"
        header-row-class-name="thead-light"
        :data="response.faq.data"
        style="width: 100%"
        @selection-change="alertsMultiSelect"
      >
        <el-table-column
          v-for="(column,index) in tableColumns"
          :key="column.label"
          v-bind="column"
        >
          <template v-if="index===3" scope="scope">
              <span :class="[column.prop, getColumn(scope.row, column)]">
                <div class="d-inline-block indicator" :class="getColumn(scope.row, column)"></div>
                {{ getColumn(scope.row, column) }}
              </span>
          </template>
          <template v-else-if="index===2" scope="scope">
            <span v-html="getColumn(scope.row, column)"></span>
          </template>
          <template v-else-if="index!==0" scope="scope">
            <span>{{ getColumn(scope.row, column) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-bind="lastColumn">
          <div slot-scope="{ $index, row }" class="d-flex">
            <img
              class="pointer mr-2"
              @click="handleShow($index)"
              src="/img/icons/buttons/show.svg"
              alt="Show"
            />
            <img
              class="pointer mr-2"
              @click="handleEdit($index, row)"
              src="/img/icons/buttons/edit.svg"
              alt="Edit"
            />
            <img
              class="pointer"
              @click="handleDelete($index, row)"
              src="/img/icons/buttons/delete.svg"
              alt="Delete"
            />
          </div>
        </el-table-column>
      </el-table>
      <div class="pt-5 w-100 d-flex justify-content-end">
        <custom-pagination
          class="pr-5"
          :total="response.faq.total"
          v-model="response.faq.current_page"
          :per-page="response.faq.per_page"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeFaq"
        @onRemoveMultiple="deleteFaq"
      />
      <faq-modal
        title="Add data from the web"
        :modal-visible="modals.addData.visibility"
        :form-data="modals.addData.form"
        :loading="modals.addData.loading"
        ref="faqModal"
        @submit="handleSubmit"
        @close="modals.addData.visibility = false"
      />
    </div>
  </div>
</template>

<script>

import {Table, TableColumn} from "element-ui";
import CustomPagination from "../../Components/Pagination/CustomPagination";
const  FaqModal = () => import("@/views/Components/Modals/AddUrlModal.vue");
const RemoveModal = () => import("@/components/Modals/RemoveModal.vue");

export default {
  name: "FaqList",
  components: {
    FaqModal,
    CustomPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
    indicatorClass(){
      return (this.modals.addData.form.is_facebook)?'active':'inactive'
    }
  },
  data(){
    return {
      loaders: {
        faq: false,
        removeModal: false,
        checkboxButton: false,
        locations: false
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      modals: {
        addData: {
          form:{
            url: '',
            is_facebook: true,
          },
          visibility: false,
          loading: false
        }
      },
      response: {
        faq: {
          data: [],
          current_page:1,
          per_page:10,
          total:0,
        },
      },
      tableColumns: [
        {
          prop: "selected",
          type: 'selection',
          width: 48,
        },
        {
          prop: "question",
          label: "Question",
          minWidth: 160,
        },
        {
          prop: "answer",
          label: "Answer",
          minWidth: 240,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 110,
        },
      ],
      lastColumn: {
        label: "Actions",
        minWidth: 130,
        width: 140
      },
      params: {
        page: 1
      }
    }
  },
  methods: {
    removeFaq(id) {
      this.deleteRequest(id)
    },
    deleteFaq() {
      this.deleteRequest(this.removeId)
    },
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },
    handleLongString(longString) {
      return (longString.length > 15) ? longString.slice(0, 15) + '...' : longString
    },
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },
    handleSubmit({is_facebook,url}) {
      const obj = {is_facebook,url}
      this.storeFaqMenuDataRequest(obj)
      this.modals.addData.visibility = false
    },
    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Faq.';
      this.removeId = row.id;
    },
    getColumn(row, column) {
      if (column.prop === 'status') {
        return this.getStatus(row.status);
      } else {
        return row[column.prop]
      }
    },
    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },
    handleShow(index) {
      this.$router.push({
        name: 'FaqDetails',
        params: {
          id : this.response.faq.data[index].id
        }
      })
    },
    handleEdit(index) {
      this.$router.push({
        name: 'EditFaq',
        params: {
          newFlag: false,
          id: this.response.faq.data[index].id
        }
      })
    },
    handleDelete(index, row) {
      this.promptRemove(row)
    },
    alertsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },
    clearSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    changePage(page) {
      this.params.page = page
      this.fetchFaqRequest()
    },

    errorRoutine(error){
      this.$notify.error({
        title: 'Error',
        message: 'Something went wrong try again later'
      })
      console.error(error.response)
    },

    fetchFaqRequest() {
      this.loaders.faq = true
      const config = {
        params: this.params,
      }
      axios.get(this.$store.getters.getBaseUrl + '/api/content/faqs',config)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.clearSelection()
          this.response.faq = response.data.data
        })
        .catch((error) => this.errorRoutine(error))
        .finally(() => this.loaders.faq = false)
    },
    deleteRequest(data) {
      this.loaders.checkboxButton = true
      const payload = {
        ids: Array.isArray(data) ? data : [data],
      }
      axios.post(this.$store.getters.getBaseUrl + `/api/content/faqs/delete`, payload)
        .then(() => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = []
          this.fetchFaqRequest()
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.log(error))
        .finally(()=> this.loaders.checkboxButton = false)
    },
    statusUpdateRequest(ids, status) {
      this.loaders.checkboxButton = true
      const payload = {
        ids: Array.isArray(ids) ? ids : [ids],
      }
      status = + status
      axios.post(this.$store.getters.getBaseUrl + `/api/content/faqs/update/${status}`, payload)
        .then(() => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = []
          this.fetchFaqRequest()
        })
        .catch((error) => console.error(error.message))
        .finally(()=> this.loaders.checkboxButton = false)
    },
    fetchFaqMenuDataRequest(){
      this.modals.addData.loading = true
      axios.get(this.$store.getters.getBaseUrl + `/api/content/faqs/menu-data/get`)
      .then((response)=> {
        this.$store.commit('toggleSessionCheck', true)
        this.$store.commit('updateSessionTime', 0)
        this.modals.addData.form = response.data.data ?? this.modals.addData.form
      })
      .catch((error) => console.log(error))
      .finally(()=>this.modals.addData.loading = false)
    },
    storeFaqMenuDataRequest(data){
      this.modals.addData.loading = true
      axios.post(this.$store.getters.getBaseUrl + `/api/content/faqs/menu-data/create`,data)
        .then((response)=> {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$notify.success({
            title: 'Success',
            message: 'Updated Successfully.'
          })
          this.fetchFaqMenuDataRequest();
        })
        .finally(()=>this.modals.addData.loading = false)
    }
  },
  mounted() {
    this.fetchFaqRequest();
    this.fetchFaqMenuDataRequest()
  },
}
</script>

<style scoped>

.form-label{
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0;
  color: #525F7F;
}

.text-clip{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

</style>
