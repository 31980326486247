<template>
  <div>
    <div class="ml-4 pl-3">
      <route-back-button/>
    </div>
    <div v-loading="loader.form" class="m-0 px-3 pt-4 pb-0 pr-2">
      <div class="row m-0 justify-content-between align-items-center mb-2">
        <h1 class="pl-3">View Event</h1>
        <router-link :to="`/faq/${this.$route.params.id}/edit`">
          <el-button class="mr-3 py-2 px-5 white-outline-button font-weight-normal">Edit</el-button>
        </router-link>
      </div>
      <div class="row m-0 ">
        <div class="col-12">
          <card>
            <div slot="header">
              <h1 class="w-100">{{ form.question }}</h1>
              <p class="patient-status px-4 py-1" style="width: fit-content"
                 :class="form.status == 0 ? 'inactive' : 'active'">
                {{ getStatus }}
              </p>
            </div>
            <div>
              <h3>ANSWER</h3>
              <p v-html="form.answer"></p>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "../../../components/Router/BackButton";

export default {
  name: "FaqDetails",
  components: {
    RouteBackButton: BackButton
  },
  data(){
    return{
      form:{
        question: '',
        answer: '',
        status: '1'
      },
      loader: {
        form: false
      }
    }
  },
  methods: {

    setValues(faq) {
      this.form.question = faq.question
      this.form.answer = faq.answer
      this.form.status = (faq.status)? '1':'0'
    },
    fetchFaqRequest() {
      this.loader.form = true
      axios.get(this.$store.getters.getBaseUrl + `/api/content/faqs/${this.$route.params.id}`)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.setValues(response.data.data)
        })
        .catch(() => {
          this.$notify.error({
            title: 'Error',
            message: 'Event might have been deleted'
          })
          this.$router.replace('/faqs')
        })
        .finally(() => this.loader.form = false)
    },
  },
  computed: {
    getStatus() {
      return this.form.status == 0 ? 'Inactive' : 'Active';
    },
  },
  mounted() {
    this.fetchFaqRequest()
  }

}
</script>

<style scoped>

.patient-status {
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0.48px;
  color: #FFFFFF;
}

.active {
  background: #94B527 0 0 no-repeat padding-box;
}

.inactive {
  background: #BB2255 0 0 no-repeat padding-box;
}

</style>
