<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <div class="pl-2">
      <route-back-button/>
    </div>
    <h1>{{HeadingString}} FAQ</h1>
    <el-form v-loading="loader.form" :model="EventForm" ref="eventForm"  @submit="handleSubmit">

      <card class="mt-4">

        <div class="row">
          <div class="col-12 col-md-6">
            <h4 class="form-label">Question*</h4>
            <el-form-item
              prop="question"
              :rules="rules.question"
            >
              <base-input
                v-model="EventForm.question"
              ></base-input>
            </el-form-item>
          </div>

          <div class="col-12 col-md-6">
            <h4 class="form-label">Status*</h4>
            <base-input>
              <el-select v-model="EventForm.status" :default-first-option="true">
                <el-option value="1" label="Active"></el-option>
                <el-option value="0" label="Inactive"></el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row" >
          <div class="col-12 ">
            <h4 class="form-label">Answer*</h4>
            <el-form-item
              prop="answer"
              :rules="rules.answer"
            >
              <editor
                v-model="EventForm.answer"
                :api-key=editorApiKey
                :init=editorOption
              />
            </el-form-item>
          </div>
        </div>

      </card>
      <el-button class="btn-primary-action font-weight-bolder px-5" type="submit"
                 :disabled="loader.form"
                 @click="handleSubmit"
      >{{ HeadingString }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import {store} from "@/vuex/store";
import swalFireMixin from "../../../mixins/swalFireMixin";
import Helper from "@/util/globalHelpers";
export default {
  name: "FaqForm",
  components: { RouteBackButton: BackButton},
  props: {
    newFlag: {
      Boolean,
      Default: true,
      Description: 'Whether this form will be used for edit or add'
    },

  },
  mixins:[
    swalFireMixin
  ],
  computed: {
    HeadingString() {
      return (this.isNew && !this.$route.params.id) ? 'Add ' : 'Update'
    },
  },
  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      isNew: true,
      loader: {
        form: false,
      },
      locations: [],
      EventForm: {
        question: '',
        answer: '',
        status: '1'
      },
      rules: {
        question: [
          { required: true, message: 'Please enter question name', trigger: 'blur' },
        ],
        answer: [
          { required: true, message: 'Please type Answer', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {

    setValues(event) {
      this.EventForm.question = event.question
      this.EventForm.answer = event.answer
      this.EventForm.status = (event.status)? '1':'0'
    },

    handleSubmit() {
      let validForm ='form valid'
      this.$refs['eventForm'].validate((valid) => {
        if (!valid) {
          validForm = false;
          return false;
        }
      })
      if (validForm) {
        this.EventForm.answer = Helper.addCustomStylingToContent(this.EventForm.answer)
        if (this.$route.params.id)
          this.editEventRequest()
        else
          this.createFaqRequest()
      }else {
        this.$notify.error({
          title: 'Missing Field',
          message: 'Enter missing Fields'
        })
      }
    },

    createFaqRequest(){
      this.loader.form = true
      axios.post(this.$store.getters.getBaseUrl+'/api/content/faqs/create',this.EventForm)
        .then(()=> {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$notify.success({
            title: 'Success',
            message: 'FAQ created successfully.'
          })
          this.$router.replace('/faqs')
        })
        .catch(()=>{
          this.$notify.error({
            title: 'Failure',
            message: 'Something went wrong try again later'
          })
        })
        .finally(()=>this.loader.form = false)
    },

    editEventRequest(){
      let vm = this;
      this.loader.form = true
      axios.post(this.$store.getters.getBaseUrl+`/api/content/faqs/create/${this.$route.params.id}`
        ,this.EventForm)
        .then(()=> {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          vm.showContentUpdateAlert(function() { vm.$router.push({name: 'FaqList'}) });
        })
        .catch(()=>{
          this.$notify.error({
            title: 'Failure',
            message: 'Something went wrong try again later'
          })
        })
        .finally(()=>this.loader.form = false)
    },

    fetchFaqRequest() {
      this.loader.form = true
      axios.get(this.$store.getters.getBaseUrl + `/api/content/faqs/${this.$route.params.id}`)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.setValues(response.data.data)
        })
        .catch(() => {
          this.$notify.error({
            title: 'Error',
            message: 'Event might have been deleted'
          })
          this.$router.go(1)
        })
        .finally(() => this.loader.form = false)
    },
  },
  mounted() {
    if (this.newFlag === false) {
      this.isNew = false
    }
    if (this.event) {
      this.setValues(this.event)
    }
    if (this.$route.params.id)
      this.fetchFaqRequest()
  }
}
</script>

<style scoped>

.form-label{
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
}

.el-button:active, .el-button:focus{
  color: #FFFFFF;
  border-radius: 6px
}



</style>
 <style>
 .el-form-item__content{
   line-height: unset !important;
 }
 </style>
